@charset 'UTF-8';

@import '_sanitize.scss';

//*============*//
//* common
//*============*//
body {
    background: #fff; 
    width: 100%;
    min-width: 1000px; 
    font: 14px/1.2 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック' ,sans-serif;
    color: #000;
}
li,
dt,
dd {
    list-style: none;
}
img {
    vertical-align: bottom;
}
a {
    color: #006cff;
}
a:hover,
a:focus {
    opacity: .9;
}
.container {
    background: url('../images/bg_main.jpg') center top no-repeat;
    background-size: cover;
}

//*============*//
//* table
//*============*//
table {
    border-top: 1px solid #999;
    border-right: 1px solid #999;
    width: 100%;
}
th {
    background: #edf5ff;
    border-bottom: 1px solid #999;
    border-left: 1px solid #999;
    padding: 20px;
    text-align: left;
    width: 29%;
    font-size: 15px;
    font-weight: normal;
    span {
        margin: 0 0 0 5px;
        color: #cb0000;
    }
}
td {
    border-bottom: 1px solid #999;
    padding: 20px;
    overflow: hidden;
    span {
        font-size: 12px;
    }
}

//*============*//
//* header
//*============*//
.m-header {
    background: url('../images/bg_header.png') center -25px no-repeat;
    background-size: cover;
    .header {
        &__inner {
            width: 1000px;
            height: 160px;
            padding: 22px 0 0;
            margin: 0 auto;
        }
        &__logo {
            padding: 0 0 0 10px;
            margin: 0 0 30px;
        }
        &__gNavList {
            overflow: hidden;
            *zoom: 1;
            padding: 0 0 0 8px;
        }
        &__gNavListItem {
            float: left;
            margin: 0 0 0 9px;
            &:first-child {
                margin: 0;
            }
            a {
                display: inline-block;
            }
        }
    }
}

//*============*//
//* footer
//*============*//
.m-footer {
    background: #00479d;
    .footer {
        &__inner {
            width: 980px;
            padding: 25px 0 10px;
            margin: 0 auto;
            text-align: center;
        }
        &__fNav {
            margin: 0 0 25px;
        }
        &__fNavListItem {
            display: inline-block;
            padding: 0 10px;
            border-left: 1px solid #fff;
            &:first-child {
                border: none;
            }
            a {
                color: #fff;
                text-decoration: underline;
            }
        }
        &__cooyRight {
            font-size: 12px;
            color: #fff;
        }
    }
}

//*============*//
//* underLayer heading
//*============*//
.m-heading {
    width: 980px;
    margin: 0 auto 25px;
    h1 {
        margin: 0 0 15px;
        box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .2);
    }
    .heading {
        &__list {
            overflow: hidden;
            *zoom: 1;
        }
        &__listItem {
            position: relative;
            float: left;
            padding: 0 0 0 15px;
            margin: 0 0 0 10px;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '>';
            }
            &:first-child {
                padding: 0;
                margin: 0;
                &:before {
                    content: '';
                }
            }
            a {
                text-decoration: underline;  
            }
        }
    }
}

//*============*//
//* contents
//*============*//
.m-contents {
    width: 980px;
    margin: 0 auto 60px;
    *zoom: 1;
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}
.m-mainContents {
    width: 700px;
    padding: 30px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .2);
    &.leftContents {
        float: left;
    }
    &.rightContents {
        float: right;
    }
    .mainContents {
        &__heading {
            line-height: 1.5;
            font-weight: bold;
            color: #00479d;
            span {
                margin: 0 0 0 10px;
                font-weight: normal;
            }
            &.top {
                margin: 0 0 10px;
                font-size: 16px;
                span {
                    font-size: 12px;
                }
            }
            &.underLayer {
                border-bottom: 2px solid #00479d;
                margin: 0 0 20px;
                font-size: 18px;
            }
        }
    }
}
.m-sideNav {
    width: 250px;
    &.leftNav {
        float: left;
    }
    &.rightNav {
        float: right;
    }
    .sideNav {
        &__contact {
            background: #fff;
            padding: 15px 0 30px;
            margin: 0 0 20px; 
            text-align: center;
            border-radius: 5px;
            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, .2);
        }
    }
    .subNav {
        &__heading {
            margin: 0 0 20px;
            font-size: 16px;
            font-weight: bold;
            color: #00479d;
            span {
                margin: 0 0 0 10px;
                font-size: 12px;
                font-weight: normal;
            }
        }
        &__txt {
            margin: 0 0 15px;
            line-height: 1.5;
            color: #484848;
        }
        &__form {
            p {
                margin: 0 0 5px;
                color: #484848;
            }
            a {
                display: inline-block;
            }
        }
        &__listItem {
            a {
                display: block;
                background: #fff url('../images/ico_arrow_r02.png') 15px center no-repeat;
                border: 2px solid #00479d;
                border-radius: 5px;
                padding: 15px 0;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                color: #00479d;
                span {
                    margin: 0 0 0 10px;
                    font-size: 12px;
                    font-weight: normal;
                }
                &:hover,
                &:focus {
                    opacity: .8;
                }
            }
            &:first-child {
                margin: 0 0 20px;
            }
        }
    }
}

//*============*//
//* top
//*============*//
.m-mainVisual {
    position: relative;
    width: 980px;
    margin: 0 auto 30px;
    .mainVisual {
        &__list {
            margin: 0 0 10px;
            overflow: hidden;
            *zoom: 1;
        }
        &__listItem {
            float: left;
            border-radius: 5px;
            overflow: hidden;
        }
    }
    .control-nav {
        text-align: center;
        li {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px;
            a {
                display: block;
                width: 8px;
                height: 8px;
                background: #a5a5a5;
                border-radius: 4px;
                text-indent: -9999px;
                &.active {
                    background: #fff;
                    border: 1px solid #a5a5a5;
                    box-sizing: border-box;
                }
            }
            
        }
    }
    .direction-nav {
        position: absolute;
        bottom: -5px;
        left: 50%;
        width: 100px;
        height: 23px;
        margin-left: -50px;
        li {
            position: absolute;
            top: 0;
            &.nav-prev {
                left: 0;
            }
            &.nav-next {
                right: 0;
            }
            a {
                display: inline-block;
            }
        }
    }
}
.m-contents {
    .mainContents {
        &__faceBook {
            text-align: center;
        }
    }
}

//*============*//
//* Service
//*============*//
.m-mainContents {
    .serviceTop {
        &__list {
            text-align: center;
        }
        &__listItem {
            display: inline-block;
            *display: inline;
            *zoom: 1;
            margin: 7px 0;
            a {
                display: inline-block;
                &:hover {
                    opacity: .7;
                }
            }
        }
    }
    .serviceFood {
        &__description {
            overflow: hidden;
            margin: 0 0 40px;
        }
        &__txt {
            float: right;
            width: 320px;
            font-size: 13px;
            p:first-child {
                margin: 0 0 1em;
            }
        }
        &__img {
            float: left;
            width: 300px; 
        }
        &__heading {
            margin: 0 0 20px;
            font-size: 16px;
            color: #00479d;
        }
        &__item {
            .taimeiken {
                &__list {
                    margin: 0 0 20px;
                    text-align: center;
                }
                &__listItem {
                    display: inline-block;
                    width: 49%;
                    margin: 0 0 20px;
                    text-align: center;
                    vertical-align: top;
                    box-sizing: border-box;
                    &:nth-child(odd) {
                        padding: 0 10px 0 0;
                    }
                    &:nth-child(even) {
                        padding: 0 0 0 10px;
                    }
                    img {
                        margin: 0 0 10px;
                    }
                    b {
                        display: block;
                        margin: 0 0 10px;
                        line-height: 1.5;
                        color: #00479d;
                    }
                    p {
                        text-align: left;
                        font-size: 13px;
                    }
                }

            }
            .nikusui {
                &__description {
                    overflow: hidden;
                    margin: 0 0 40px;
                }
                &__txt {
                    float: right;
                    width: 320px;
                    font-size: 13px;
                    b {
                        line-height: 2;
                    }
                    p:first-child {
                        margin: 0 0 1em;
                    }
                }
                &__img {
                    float: left;
                    width: 300px; 
                }
            }
        }
    }
    .serviceLogistics {
        &__description {
            margin: 0 0 40px;
            font-size: 13px;
        }
        &__heading {
            margin: 0 0 10px;
            font-size: 16px;
            color: #00479d;
        }
        &__item {
            margin: 0 0 50px;
            font-size: 13px;
            .processing {
                &__item {
                    margin: 0 0 30px;
                    overflow: hidden;
                }
                &__txt {
                    float: right;
                    width: 320px;
                    li {
                        padding: 0 0 0 1em;
                        text-indent: -1em;
                    }
                }
                &__img {
                    float: left;
                    width: 299px;
                }
            }
            .work {
                &__item {
                    margin: 0 0 30px;
                    overflow: hidden;
                }
                &__txt {
                    float: right;
                    width: 320px;
                    b {
                        display: block;
                        margin: 0 0 1em;
                    }
                    p {
                        margin: 1em 0 0;
                    }
                    &--supplement {
                        margin: 20px 0 0;
                        overflow: hidden;
                        .supplement {
                            &__txt {
                                float: right;
                                width: 160px;
                                font-size: 12px;
                                b {
                                    font-weight: normal;
                                }
                            }
                            &__img {
                                float: left;
                                width: 150px;
                            }
                        }
                    }
                }
                &__img {
                    float: left;
                    width: 299px;
                }
            }
        }
    }
    .servicePackage {
        &__description {
            margin: 0 0 40px;
            font-size: 13px;
        }
        &__heading {
            margin: 0 0 10px;
            font-size: 16px;
            color: #00479d;
        }
        &__item {
            margin: 0 0 20px;
            font-size: 13px;
            overflow: hidden;
        }
        &__txt {
            float: left;
            width: 260px;
            padding: 15px 0 0;
        }
        &__img {
           float: right;
           width: 380px;
           text-align: center;   
        }
    }
    .serviceShanghai {
        &__description {
            padding: 0 0 130px;
            font-size: 13px;
        }
    }
}

//*============*//
//* Outline
//*============*//
.m-mainContents {
    .mainContents {
        &__greeting {
            margin: 0 0 50px;
            font-size: 13px;
        }
        &__outline {
            td {
                .customers {
                    float: left;
                    width: 60%;
                    &:first-child {
                        width: 40%;
                    }
                }
                li {
                    line-height: 1.8;
                }
                dl {
                    overflow: hidden;
                }
                dt {
                    float: left;
                    width: 120px;
                    margin: 0 0 10px;
                }
                dd {
                    float: left;
                    width: 300px;
                    margin: 0 0 10px;
                }
            }
        }
    }
}

//*============*//
//* News
//*============*//
.m-mainContents {
    .news {
        &__faceBook {
            text-align: center;
        }
    }
}

//*============*//
//* Contact
//*============*//
.m-mainContents {
    .m-contact {
        table {
            margin: 0 0 50px;
        }
        input {
            width: 300px;
            border: 1px solid #999;
            padding: 0 5px;
            margin: 0 0 5px;
            line-height: 2;
        }
        textarea {
            width: 100%;
            height: 200px;
            border: 1px solid #999;
            padding: 5px;
            margin: 0 0 5px;
        }
    }
    .contact {
        &__txt {
            margin: 0 0 20px;
            b {
                display: block;
                margin: 0 0 10px;
                font-size: 16px;
                color: #cb0000;
            }
        }
        &__message {
            margin: 0 0 50px;
            &--error {
                color: #cb0000;
            }
        }
        &__btn {
            padding: 0 0 50px;
            text-align: center;
            li:first-child {
                margin: 0 0 30px;
            }
            a,
            input,
            button {
                display: block;
                background: linear-gradient(0deg, #00479d, #2575d6);
                border: none;
                border-radius: 5px;
                margin: 0 auto;
                width: 230px;
                height: 55px;
                line-height: 55px;    
                font-weight: bold;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
