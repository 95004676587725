@charset "UTF-8";
/*! sanitize.css v3.0.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
audio:not([controls]) {
  display: none; }

button {
  overflow: visible;
  -webkit-appearance: button; }

details {
  display: block; }

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%; }

input {
  -webkit-border-radius: 0; }
  input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button; }
  input[type="number"] {
    width: auto; }
  input[type="search"] {
    -webkit-appearance: textfield; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }

main {
  display: block; }

pre {
  overflow: auto; }

progress {
  display: inline-block; }

small {
  font-size: 75%; }

summary {
  display: block; }

svg:not(:root) {
  overflow: hidden; }

template {
  display: none; }

textarea {
  overflow: auto; }

[hidden] {
  display: none; }

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit; }

* {
  font-size: inherit;
  line-height: inherit; }

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit; }

/*
 * Opinionated defaults
 */
*,
::before,
::after {
  border-style: solid;
  border-width: 0; }

* {
  background-repeat: no-repeat;
  margin: 0;
  padding: 0; }

:root {
  background-color: #FFFFFF;
  box-sizing: border-box;
  color: #000000;
  cursor: default;
  font: 100% / 1.5 sans-serif;
  text-rendering: optimizeLegibility; }

a {
  text-decoration: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

button,
input,
select,
textarea {
  background-color: transparent; }

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit; }

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; }

nav ol,
nav ul {
  list-style: none; }

select {
  -moz-appearance: none;
  -webkit-appearance: none; }
  select::-ms-expand {
    display: none; }
  select::-ms-value {
    color: currentColor; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: vertical; }

::-moz-selection {
  background-color: #B3D4FC;
  color: #ffffff;
  text-shadow: none; }

::selection {
  background-color: #B3D4FC;
  color: #ffffff;
  text-shadow: none; }

[aria-busy="true"] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute; }
  [hidden][aria-hidden="false"]:focus {
    clip: auto; }

body {
  background: #fff;
  width: 100%;
  min-width: 1000px;
  font: 14px/1.2 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック' ,sans-serif;
  color: #000; }

li,
dt,
dd {
  list-style: none; }

img {
  vertical-align: bottom; }

a {
  color: #006cff; }

a:hover,
a:focus {
  opacity: .9; }

.container {
  background: url("../images/bg_main.jpg") center top no-repeat;
  background-size: cover; }

table {
  border-top: 1px solid #999;
  border-right: 1px solid #999;
  width: 100%; }

th {
  background: #edf5ff;
  border-bottom: 1px solid #999;
  border-left: 1px solid #999;
  padding: 20px;
  text-align: left;
  width: 29%;
  font-size: 15px;
  font-weight: normal; }
  th span {
    margin: 0 0 0 5px;
    color: #cb0000; }

td {
  border-bottom: 1px solid #999;
  padding: 20px;
  overflow: hidden; }
  td span {
    font-size: 12px; }

.m-header {
  background: url("../images/bg_header.png") center -25px no-repeat;
  background-size: cover; }
  .m-header .header__inner {
    width: 1000px;
    height: 160px;
    padding: 22px 0 0;
    margin: 0 auto; }
  .m-header .header__logo {
    padding: 0 0 0 10px;
    margin: 0 0 30px; }
  .m-header .header__gNavList {
    overflow: hidden;
    *zoom: 1;
    padding: 0 0 0 8px; }
  .m-header .header__gNavListItem {
    float: left;
    margin: 0 0 0 9px; }
    .m-header .header__gNavListItem:first-child {
      margin: 0; }
    .m-header .header__gNavListItem a {
      display: inline-block; }

.m-footer {
  background: #00479d; }
  .m-footer .footer__inner {
    width: 980px;
    padding: 25px 0 10px;
    margin: 0 auto;
    text-align: center; }
  .m-footer .footer__fNav {
    margin: 0 0 25px; }
  .m-footer .footer__fNavListItem {
    display: inline-block;
    padding: 0 10px;
    border-left: 1px solid #fff; }
    .m-footer .footer__fNavListItem:first-child {
      border: none; }
    .m-footer .footer__fNavListItem a {
      color: #fff;
      text-decoration: underline; }
  .m-footer .footer__cooyRight {
    font-size: 12px;
    color: #fff; }

.m-heading {
  width: 980px;
  margin: 0 auto 25px; }
  .m-heading h1 {
    margin: 0 0 15px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2); }
  .m-heading .heading__list {
    overflow: hidden;
    *zoom: 1; }
  .m-heading .heading__listItem {
    position: relative;
    float: left;
    padding: 0 0 0 15px;
    margin: 0 0 0 10px; }
    .m-heading .heading__listItem:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '>'; }
    .m-heading .heading__listItem:first-child {
      padding: 0;
      margin: 0; }
      .m-heading .heading__listItem:first-child:before {
        content: ''; }
    .m-heading .heading__listItem a {
      text-decoration: underline; }

.m-contents {
  width: 980px;
  margin: 0 auto 60px;
  *zoom: 1; }
  .m-contents:before, .m-contents:after {
    content: '';
    display: table; }
  .m-contents:after {
    clear: both; }

.m-mainContents {
  width: 700px;
  padding: 30px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2); }
  .m-mainContents.leftContents {
    float: left; }
  .m-mainContents.rightContents {
    float: right; }
  .m-mainContents .mainContents__heading {
    line-height: 1.5;
    font-weight: bold;
    color: #00479d; }
    .m-mainContents .mainContents__heading span {
      margin: 0 0 0 10px;
      font-weight: normal; }
    .m-mainContents .mainContents__heading.top {
      margin: 0 0 10px;
      font-size: 16px; }
      .m-mainContents .mainContents__heading.top span {
        font-size: 12px; }
    .m-mainContents .mainContents__heading.underLayer {
      border-bottom: 2px solid #00479d;
      margin: 0 0 20px;
      font-size: 18px; }

.m-sideNav {
  width: 250px; }
  .m-sideNav.leftNav {
    float: left; }
  .m-sideNav.rightNav {
    float: right; }
  .m-sideNav .sideNav__contact {
    background: #fff;
    padding: 15px 0 30px;
    margin: 0 0 20px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2); }
  .m-sideNav .subNav__heading {
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: bold;
    color: #00479d; }
    .m-sideNav .subNav__heading span {
      margin: 0 0 0 10px;
      font-size: 12px;
      font-weight: normal; }
  .m-sideNav .subNav__txt {
    margin: 0 0 15px;
    line-height: 1.5;
    color: #484848; }
  .m-sideNav .subNav__form p {
    margin: 0 0 5px;
    color: #484848; }
  .m-sideNav .subNav__form a {
    display: inline-block; }
  .m-sideNav .subNav__listItem a {
    display: block;
    background: #fff url("../images/ico_arrow_r02.png") 15px center no-repeat;
    border: 2px solid #00479d;
    border-radius: 5px;
    padding: 15px 0;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #00479d; }
    .m-sideNav .subNav__listItem a span {
      margin: 0 0 0 10px;
      font-size: 12px;
      font-weight: normal; }
    .m-sideNav .subNav__listItem a:hover, .m-sideNav .subNav__listItem a:focus {
      opacity: .8; }
  .m-sideNav .subNav__listItem:first-child {
    margin: 0 0 20px; }

.m-mainVisual {
  position: relative;
  width: 980px;
  margin: 0 auto 30px; }
  .m-mainVisual .mainVisual__list {
    margin: 0 0 10px;
    overflow: hidden;
    *zoom: 1; }
  .m-mainVisual .mainVisual__listItem {
    float: left;
    border-radius: 5px;
    overflow: hidden; }
  .m-mainVisual .control-nav {
    text-align: center; }
    .m-mainVisual .control-nav li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px; }
      .m-mainVisual .control-nav li a {
        display: block;
        width: 8px;
        height: 8px;
        background: #a5a5a5;
        border-radius: 4px;
        text-indent: -9999px; }
        .m-mainVisual .control-nav li a.active {
          background: #fff;
          border: 1px solid #a5a5a5;
          box-sizing: border-box; }
  .m-mainVisual .direction-nav {
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 100px;
    height: 23px;
    margin-left: -50px; }
    .m-mainVisual .direction-nav li {
      position: absolute;
      top: 0; }
      .m-mainVisual .direction-nav li.nav-prev {
        left: 0; }
      .m-mainVisual .direction-nav li.nav-next {
        right: 0; }
      .m-mainVisual .direction-nav li a {
        display: inline-block; }

.m-contents .mainContents__faceBook {
  text-align: center; }

.m-mainContents .serviceTop__list {
  text-align: center; }

.m-mainContents .serviceTop__listItem {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin: 7px 0; }
  .m-mainContents .serviceTop__listItem a {
    display: inline-block; }
    .m-mainContents .serviceTop__listItem a:hover {
      opacity: .7; }

.m-mainContents .serviceFood__description {
  overflow: hidden;
  margin: 0 0 40px; }

.m-mainContents .serviceFood__txt {
  float: right;
  width: 320px;
  font-size: 13px; }
  .m-mainContents .serviceFood__txt p:first-child {
    margin: 0 0 1em; }

.m-mainContents .serviceFood__img {
  float: left;
  width: 300px; }

.m-mainContents .serviceFood__heading {
  margin: 0 0 20px;
  font-size: 16px;
  color: #00479d; }

.m-mainContents .serviceFood__item .taimeiken__list {
  margin: 0 0 20px;
  text-align: center; }

.m-mainContents .serviceFood__item .taimeiken__listItem {
  display: inline-block;
  width: 49%;
  margin: 0 0 20px;
  text-align: center;
  vertical-align: top;
  box-sizing: border-box; }
  .m-mainContents .serviceFood__item .taimeiken__listItem:nth-child(odd) {
    padding: 0 10px 0 0; }
  .m-mainContents .serviceFood__item .taimeiken__listItem:nth-child(even) {
    padding: 0 0 0 10px; }
  .m-mainContents .serviceFood__item .taimeiken__listItem img {
    margin: 0 0 10px; }
  .m-mainContents .serviceFood__item .taimeiken__listItem b {
    display: block;
    margin: 0 0 10px;
    line-height: 1.5;
    color: #00479d; }
  .m-mainContents .serviceFood__item .taimeiken__listItem p {
    text-align: left;
    font-size: 13px; }

.m-mainContents .serviceFood__item .nikusui__description {
  overflow: hidden;
  margin: 0 0 40px; }

.m-mainContents .serviceFood__item .nikusui__txt {
  float: right;
  width: 320px;
  font-size: 13px; }
  .m-mainContents .serviceFood__item .nikusui__txt b {
    line-height: 2; }
  .m-mainContents .serviceFood__item .nikusui__txt p:first-child {
    margin: 0 0 1em; }

.m-mainContents .serviceFood__item .nikusui__img {
  float: left;
  width: 300px; }

.m-mainContents .serviceLogistics__description {
  margin: 0 0 40px;
  font-size: 13px; }

.m-mainContents .serviceLogistics__heading {
  margin: 0 0 10px;
  font-size: 16px;
  color: #00479d; }

.m-mainContents .serviceLogistics__item {
  margin: 0 0 50px;
  font-size: 13px; }
  .m-mainContents .serviceLogistics__item .processing__item {
    margin: 0 0 30px;
    overflow: hidden; }
  .m-mainContents .serviceLogistics__item .processing__txt {
    float: right;
    width: 320px; }
    .m-mainContents .serviceLogistics__item .processing__txt li {
      padding: 0 0 0 1em;
      text-indent: -1em; }
  .m-mainContents .serviceLogistics__item .processing__img {
    float: left;
    width: 299px; }
  .m-mainContents .serviceLogistics__item .work__item {
    margin: 0 0 30px;
    overflow: hidden; }
  .m-mainContents .serviceLogistics__item .work__txt {
    float: right;
    width: 320px; }
    .m-mainContents .serviceLogistics__item .work__txt b {
      display: block;
      margin: 0 0 1em; }
    .m-mainContents .serviceLogistics__item .work__txt p {
      margin: 1em 0 0; }
    .m-mainContents .serviceLogistics__item .work__txt--supplement {
      margin: 20px 0 0;
      overflow: hidden; }
      .m-mainContents .serviceLogistics__item .work__txt--supplement .supplement__txt {
        float: right;
        width: 160px;
        font-size: 12px; }
        .m-mainContents .serviceLogistics__item .work__txt--supplement .supplement__txt b {
          font-weight: normal; }
      .m-mainContents .serviceLogistics__item .work__txt--supplement .supplement__img {
        float: left;
        width: 150px; }
  .m-mainContents .serviceLogistics__item .work__img {
    float: left;
    width: 299px; }

.m-mainContents .servicePackage__description {
  margin: 0 0 40px;
  font-size: 13px; }

.m-mainContents .servicePackage__heading {
  margin: 0 0 10px;
  font-size: 16px;
  color: #00479d; }

.m-mainContents .servicePackage__item {
  margin: 0 0 20px;
  font-size: 13px;
  overflow: hidden; }

.m-mainContents .servicePackage__txt {
  float: left;
  width: 260px;
  padding: 15px 0 0; }

.m-mainContents .servicePackage__img {
  float: right;
  width: 380px;
  text-align: center; }

.m-mainContents .serviceShanghai__description {
  padding: 0 0 130px;
  font-size: 13px; }

.m-mainContents .mainContents__greeting {
  margin: 0 0 50px;
  font-size: 13px; }

.m-mainContents .mainContents__outline td .customers {
  float: left;
  width: 60%; }
  .m-mainContents .mainContents__outline td .customers:first-child {
    width: 40%; }

.m-mainContents .mainContents__outline td li {
  line-height: 1.8; }

.m-mainContents .mainContents__outline td dl {
  overflow: hidden; }

.m-mainContents .mainContents__outline td dt {
  float: left;
  width: 120px;
  margin: 0 0 10px; }

.m-mainContents .mainContents__outline td dd {
  float: left;
  width: 300px;
  margin: 0 0 10px; }

.m-mainContents .news__faceBook {
  text-align: center; }

.m-mainContents .m-contact table {
  margin: 0 0 50px; }

.m-mainContents .m-contact input {
  width: 300px;
  border: 1px solid #999;
  padding: 0 5px;
  margin: 0 0 5px;
  line-height: 2; }

.m-mainContents .m-contact textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #999;
  padding: 5px;
  margin: 0 0 5px; }

.m-mainContents .contact__txt {
  margin: 0 0 20px; }
  .m-mainContents .contact__txt b {
    display: block;
    margin: 0 0 10px;
    font-size: 16px;
    color: #cb0000; }

.m-mainContents .contact__message {
  margin: 0 0 50px; }
  .m-mainContents .contact__message--error {
    color: #cb0000; }

.m-mainContents .contact__btn {
  padding: 0 0 50px;
  text-align: center; }
  .m-mainContents .contact__btn li:first-child {
    margin: 0 0 30px; }
  .m-mainContents .contact__btn a,
  .m-mainContents .contact__btn input,
  .m-mainContents .contact__btn button {
    display: block;
    background: linear-gradient(0deg, #00479d, #2575d6);
    border: none;
    border-radius: 5px;
    margin: 0 auto;
    width: 230px;
    height: 55px;
    line-height: 55px;
    font-weight: bold;
    color: #fff;
    cursor: pointer; }
